import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Admin from './components/Admin';
import Home from './components/Home';
import Projects from './components/Projects';
import Cloudflare from './components/Cloudflare';
import Servers from './components/Servers';
import ChangeDNS from './components/ChangeDNS';
import TableManager from './components/TableManager';
import Sites from "./components/Sites";
import CheckSites from "./components/CheckSites";
import ProjectsNew from "./components/ProjectsNew";


const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');

  const handleSetToken = (newToken) => {
    setToken(newToken);
    if (newToken) {
      localStorage.setItem('token', newToken);
    } else {
      localStorage.removeItem('token');
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login setToken={handleSetToken} />} />
        <Route path="/" element={token ? <Dashboard token={token} setToken={handleSetToken} /> : <Navigate to="/login" />}>
          <Route index element={<Home token={token} />} />
          <Route path="projects" element={<Projects token={token} />} />
          <Route path="projects_new" element={<ProjectsNew token={token} />} />
          <Route path="sites" element={<Sites token={token} />} />
          <Route path="check-site" element={<CheckSites token={token} />} />
          <Route path="cloudflare" element={<Cloudflare token={token} />} />
          <Route path="servers" element={<Servers token={token} />} />
          <Route path="dns" element={<ChangeDNS token={token} />} />
          <Route path="admin" element={<Admin token={token} />} />
          <Route path="tables" element={<TableManager token={token} />} />
          {/* Добавьте остальные маршруты здесь */}
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
