import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Button,
    Box, Collapse, IconButton
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import API_BASE_URL from '../apiConfig';

const Sites = ({ token }) => {
    const [domains, setDomains] = useState([]);
    const [error, setError] = useState('');
    const [openDomains, setOpenDomains] = useState(true);  // Для сворачивания группы с доменами

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/user_domains`, {
                    headers: { Authorization: token }
                });
                setDomains(response.data.data);
            } catch (error) {
                setError('Failed to fetch domains');
            }
        };

        fetchDomains();
    }, [token]);

    return (
        <Container>
            {error && <Typography color="error">{error}</Typography>}

            {/* Группа для всех доменов */}
            <Box mt={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" component="h2">
                        Список доменов
                    </Typography>
                    <IconButton onClick={() => setOpenDomains(!openDomains)}>
                        {openDomains ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                <Collapse in={openDomains}>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Домен</TableCell>
                                    <TableCell>Проект</TableCell>
                                    <TableCell>Статус</TableCell>
                                    <TableCell>Дата добавления</TableCell>
                                    <TableCell>Тип</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {domains.map((domain) => (
                                    <TableRow key={domain.id}>
                                        <TableCell>{domain.domain_name}</TableCell>
                                        <TableCell>{domain.project_name || 'No Project'}</TableCell>
                                        <TableCell>{domain.status}</TableCell>
                                        <TableCell>{new Date(domain.created_at).toLocaleDateString()}</TableCell>
                                        <TableCell>{domain.type}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Collapse>
            </Box>
        </Container>
    );
};

export default Sites;
