import React, { useState } from 'react';
import {Box, Typography, Button, TextField, Chip, Divider, Container} from '@mui/material';
import axios from 'axios';
import API_BASE_URL from '../apiConfig';

const CheckSite = ({ token }) => {
    const [domainInput, setDomainInput] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [domainsResult, setDomainsResult] = useState([]);
    const [loading, setLoading] = useState(false); // Добавляем состояние загрузки



    const handleCheckDomains = async () => {
        const domainsArray = domainInput.split(',').map(domain => ({ domain: domain.trim() }));

        setLoading(true); // Устанавливаем состояние загрузки
        setResponseMessage('');
        setDomainsResult([]);

        try {
            const response = await axios.post(`${API_BASE_URL}/api/check_domains`, {
                domains: domainsArray
            }, {
                headers: { Authorization: token }
            });

            console.log('Server response:', response.data); // Логируем ответ сервера для отладки

            if (response.data && response.data.foundDomains && response.data.foundDomains.allDomains) {
                setDomainsResult(response.data.foundDomains.allDomains); // Извлекаем allDomains из foundDomains
                setResponseMessage('Проверка доменов выполнена успешно');
            } else {
                setResponseMessage('Не удалось получить результаты');
            }
        } catch (error) {
            console.error('Ошибка запроса:', error);
            setResponseMessage('Ошибка при проверке доменов');
        } finally {
            setLoading(false); // Отключаем состояние загрузки
        }
    };

    const getStatusChipColor = (status) => {
        if (status === 'blocked') return 'error';   // Красный для заблокированных
        if (status === 'free') return 'success';    // Зеленый для свободных
        return 'default';                           // По умолчанию (для неопределенных статусов)
    };

    return (
        <Container>
            <TextField
                label="Введите домены через запятую"
                fullWidth
                value={domainInput}
                onChange={(e) => setDomainInput(e.target.value)}
                margin="normal"
                sx={{
                    width: 500,
                }}
            />
            <Button variant="contained" color="primary" onClick={handleCheckDomains} sx={{ mt: 3, ml: 2 }} disabled={loading}>
                {loading ? 'Проверка...' : 'Проверить'}
            </Button>

            {/* Если есть сообщение, отображаем его */}
            {responseMessage && <Typography color="primary" mt={2}>{responseMessage}</Typography>}

            {/* Разделитель и заголовок списка доменов */}
            {domainsResult.length > 0 && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" gutterBottom>
                        Результаты проверки доменов:
                    </Typography>
                    <Box mt={2}>
                        {domainsResult.map((domain, index) => (
                            <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                <Typography>{domain.domain}</Typography>
                                <Chip
                                    label={domain.status === 'blocked' ? 'Заблокирован' : 'Свободен'}
                                    color={getStatusChipColor(domain.status)}
                                    size="small"
                                />
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </Container>
    );
};

export default CheckSite;
