import React from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {AppBar, Toolbar, Typography, Button, ButtonGroup, Box, MenuItem, Menu} from '@mui/material';
import DropdownButton from "./ui/dropdown";

const Dashboard = ({ token, setToken }) => {
  const user = jwtDecode(token);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    navigate('/login');
  };

  const isSelected = (path) => location.pathname === path;

  const buttonStyle = (path) => ({
    backgroundColor: isSelected(path) ? '#2F3235' : 'inherit',
    borderRadius: isSelected(path) ? '4px' : '0px',
    color: isSelected(path) ? '#fff' : 'inherit',
  });

  const sitesMenuItems = [
    // { label: 'Проекты', path: '/projects' },
    { label: 'Проекты', path: '/projects_new' },
    { label: 'Все сайты', path: '/sites' },
  ];
  const toolsMenuItems = [
    { label: 'Добавление в Cloudflare', path: '/dns' },
    { label: 'Проверка доменов', path: '/check-site' },
  ];
  const accMenuItem = [
    { label: 'Cloudflare', path: '/cloudflare' },
    { label: 'Серверы', path: '/servers' },
  ];
  const adminMenuItem = [
    { label: 'Создать Юзера', path: '/admin' },
    { label: 'Таблицы', path: '/tables' },
  ];

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <Button component={Link} to="/" sx={buttonStyle('/')}>Главная</Button>
          </Typography>
          <ButtonGroup variant="text" color="inherit">
            <DropdownButton
                title="Сайты"
                menuItems={sitesMenuItems}
                buttonStyle={buttonStyle}
            />
            <DropdownButton
                title="Инструменты"
                menuItems={toolsMenuItems}
                buttonStyle={buttonStyle}
            />
            <DropdownButton
                title="Аккаунты"
                menuItems={accMenuItem}
                buttonStyle={buttonStyle}
            />
            {user.role === 'admin' && (
                <DropdownButton
                  title="Админка"
                  menuItems={adminMenuItem}
                  buttonStyle={buttonStyle}
              />
            )}
          </ButtonGroup>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Dashboard;
