import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
    Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper,
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, IconButton, Box, DialogContentText, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {Edit, Delete, Add, TransferWithinAStation, History} from '@mui/icons-material';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import API_BASE_URL from '../apiConfig';
import DomainTransferDialog from "./DomainTransferDialog";
import DomainTransferHistoryDialog from "./DomainTransferHistoryDialog";
import ChangeDNS from "./ChangeDNS";
import { Tooltip } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import DomainRedirectModal from "./DomainRedirectModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { differenceInHours, parseISO } from 'date-fns'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import BackHandIcon from '@mui/icons-material/BackHand';
import PageRuleDialog from "./PageRuleDialog";

const ProjectsNew = ({ token }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [newProjectName, setNewProjectName] = useState('');
    const [editProjectName, setEditProjectName] = useState('');
    const [projectDomains, setProjectDomains] = useState({});
    const [expandedProjectId, setExpandedProjectId] = useState(null);
    const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);
    const [openEditProjectDialog, setOpenEditProjectDialog] = useState(false);
    const [openChangeDnsDialog, setOpenChangeDnsDialog] = useState(false);
    const [openRedirectModal, setOpenRedirectModal] = useState(false);
    const [redirectDomain, setRedirectDomain] = useState(null);
    const [dnsDomain, setDnsDomain] = useState(null);
    const [error, setError] = useState('');

    const [openTransferDialog, setOpenTransferDialog] = useState(false);
    const [openHistoryDialog, setOpenHistoryDialog] = useState(false);

    const [sourceDomainId, setSourceDomainId] = useState(null);
    const [historyDomainId, setHistoryDomainId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [unassignedDomains, setUnassignedDomains] = useState([]);

    const [servers, setServers] = useState([]);
    const [cloudflareAccounts, setCloudflareAccounts] = useState([]);


    const [newDomain, setNewDomain] = useState({ domain_name: '', file_path: '', type: 'main', server_id: '', project_id: '' });
    const [editDomain, setEditDomain] = useState(null);


    const [openAddDomainDialog, setOpenAddDomainDialog] = useState(false);
    const [openEditDomainDialog, setOpenEditDomainDialog] = useState(false);


    const [openPageRuleDialog, setOpenPageRuleDialog] = useState(false);
    const [pageRuleDomain, setPageRuleDomain] = useState(null);

    const handlePageRuleDialog = (domain) => {
        console.log(domain)
        setOpenPageRuleDialog(true);
        setPageRuleDomain(domain);
    };

    // Fetch all projects
    const fetchProjects = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/user_projects`, {
                headers: { Authorization: token }
            });
            setProjects(response.data.data);
        } catch (error) {
            setError('Failed to fetch projects');
        }
    }, [token]);

    // Fetch domains for a specific project
    const fetchProjectDomains = useCallback(async (projectId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/project_domains/${projectId}`, {
                headers: { Authorization: token }
            });
            setProjectDomains((prev) => ({
                ...prev,
                [projectId]: response.data.data,
            }));
        } catch (error) {
            setError('Failed to fetch project domains');
        }
    }, [token]);


    const fetchUnassignedDomains = useCallback(async (projectId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/project_unassigned_domains/${projectId}`, {
                headers: { Authorization: token }
            });
            setUnassignedDomains(response.data.data);
        } catch (error) {
            setError('Failed to fetch unassigned domains');
        }
    }, [token]);

    const fetchServers = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/servers`, {
                headers: { Authorization: token }
            });
            setServers(response.data.data);
        } catch (error) {
            setError('Failed to fetch servers');
        }
    }, [token]);

    const fetchCloudflareAccounts = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/cloudflare_accounts`, {
                headers: { Authorization: token }
            });
            setCloudflareAccounts(response.data.data);
        } catch (error) {
            setError('Failed to fetch Cloudflare accounts');
        }
    }, [token]);

    const handleOpenCreateProjectDialog = () => {
        setOpenCreateProjectDialog(true);
    };

    const handleCloseCreateProjectDialog = () => {
        setOpenCreateProjectDialog(false);
        setNewProjectName('');
    };

    const handleCreateProject = async () => {
        try {
            await axios.post(`${API_BASE_URL}/api/projects`, { name: newProjectName }, {
                headers: { Authorization: token }
            });
            fetchProjects();
            handleCloseCreateProjectDialog();
        } catch (error) {
            setError('Failed to create project');
        }
    };

    const handleOpenEditProjectDialog = (project) => {
        setSelectedProject(project);
        setEditProjectName(project.name);
        setOpenEditProjectDialog(true);
    };

    const handleCloseEditProjectDialog = () => {
        setOpenEditProjectDialog(false);
        setSelectedProject(null);
    };

    const handleEditProject = async () => {
        if (selectedProject) {
            try {
                await axios.put(`${API_BASE_URL}/api/projects/${selectedProject.id}`, { name: editProjectName }, {
                    headers: { Authorization: token }
                });
                fetchProjects();
                handleCloseEditProjectDialog();
            } catch (error) {
                setError('Failed to edit project');
            }
        }
    };

    const handleDeleteProject = async (projectId) => {
        try {
            await axios.delete(`${API_BASE_URL}/api/projects/${projectId}`, {
                headers: { Authorization: token }
            });
            fetchProjects();
        } catch (error) {
            setError('Failed to delete project');
        }
    };

    const handleExpandProject = (project) => {
        setSelectedProject(project);
        let projectId = project.id;
        setExpandedProjectId((prev) => (prev === projectId ? null : projectId));
        if (!projectDomains[projectId]) {
            fetchProjectDomains(projectId);
        }
        fetchUnassignedDomains(projectId);
    };

    const handleOpenEditDomainDialog = async (domain) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/domains/${domain.id}`, {
                headers: { Authorization: token }
            });
            setEditDomain(response.data.data);
            setOpenEditDomainDialog(true);
        } catch (error) {
            setError('Failed to fetch domain details');
        }
    };

    const handleCloseEditDomainDialog = () => {
        setOpenEditDomainDialog(false);
        setEditDomain(null);
        // Reload project data to ensure UI is up to date
        fetchProjects();
        if (editDomain?.project_id) {
            fetchProjectDomains(editDomain.project_id);
        }
    };

    const handleOpenAddDomainDialog = (project_id) => {
        setNewDomain({ ...newDomain, project_id: project_id });
        setOpenAddDomainDialog(true);
    };

    const handleCloseAddDomainDialog = () => {
        setOpenAddDomainDialog(false);
        setNewDomain({ domain_name: '', file_path: '', type: 'main', server_id: '', project_id: '' });
    };

    const handleDomainNameChange = (e) => {
        const domainName = e.target.value;
        const filePath = `/var/www/www-root/data/www/${domainName}`;
        setNewDomain({ ...newDomain, domain_name: domainName, file_path: filePath });
    };

    const handleAddDomainToProject = async () => {
        try {
            await axios.post(`${API_BASE_URL}/api/domains`, {
                ...newDomain,
                status: 'available',
            }, {
                headers: { Authorization: token }
            });
            fetchProjects();
            fetchUnassignedDomains(selectedProject.id);
            handleCloseAddDomainDialog();
            window.location.reload();
        } catch (error) {
            setError('Failed to add domain to project');
        }
    };

    const handleDomainDeleted = () => {
        handleCloseHistoryDialog();
        window.location.reload();
    };

    const handleOpenHistoryDialog = (domainId) => {
        setHistoryDomainId(domainId);
        setOpenHistoryDialog(true);
    };

    const handleCloseHistoryDialog = () => {
        setOpenHistoryDialog(false);
        setHistoryDomainId(null);
    };

    const handleEditDomain = async () => {
        try {
            await axios.post(`${API_BASE_URL}/api/domains/${editDomain.id}`, {
                ...editDomain,
            }, {
                headers: { Authorization: token }
            });
            handleCloseEditDomainDialog();
            // Reload project and domains data
            fetchProjects();
            if (editDomain.project_id) {
                fetchProjectDomains(editDomain.project_id);
            }
            // Fetch the previous project domains if project_id was changed
            if (editDomain.project_id !== selectedProject.id) {
                fetchProjectDomains(selectedProject.id);
            }
        } catch (error) {
            // setError('Failed to edit domain');
            // console.log(error)
        }
    };

    const handleDeleteDomain = async (domainId) => {
        try {
            await axios.delete(`${API_BASE_URL}/api/domains/${domainId}`, {
                headers: { Authorization: token }
            });
            setDeleteDialogOpen(false);
            fetchProjects();
            window.location.reload();

        } catch (error) {
            setError('Failed to delete domain');
        }
    };

    const handleOpenDeleteDomainDialog = (domain) => {
        setSelectedDomain(domain);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDomainDialog = () => {
        setDeleteDialogOpen(false);
        setSelectedDomain(null);
    };

    const handleOpenTransferDialog = (domainId) => {
        setSourceDomainId(domainId);
        setOpenTransferDialog(true);
    };

    const handleCloseTransferDialog = () => {
        setOpenTransferDialog(false);
        setSourceDomainId(null);
    };

    const handleTransfer = () => {
        fetchProjects();
        if (sourceDomainId) {
            const projectId = projects.find(project =>
                projectDomains[project.id]?.some(domain => domain.id === sourceDomainId)
            )?.id;
            if (projectId) {
                fetchProjectDomains(projectId);
            }
        }
    };

    const handleOpenChangeDnsDialog = (domain) => {
        setOpenChangeDnsDialog(true);
        setDnsDomain(domain);
    };

    const handleOpenRedirectModal = (domain) => {
        setOpenRedirectModal(true);
        setRedirectDomain(domain);
    };

    const handleCloseChangeDnsDialog = () => {
        setOpenChangeDnsDialog(false);
        setDnsDomain(null);
    };

    useEffect(() => {
        fetchProjects();
        fetchServers();
        fetchCloudflareAccounts();
    }, [fetchProjects, fetchServers, fetchCloudflareAccounts]);

    return (
        <Container>
            {error && <Typography color="error">{error}</Typography>}
            <Box display="flex">
                {/* Left Sidebar */}
                <Box width="15%" marginRight={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<Add />}
                        onClick={handleOpenCreateProjectDialog}
                    >
                        Создать
                    </Button>
                    <Paper elevation={1} style={{ marginTop: '16px' }}>
                        <Table>
                            <TableBody>
                                {projects.map((project) => (
                                    <TableRow
                                        key={project.id}
                                        onClick={() => handleExpandProject(project)}
                                        sx={{
                                            backgroundColor: expandedProjectId === project.id ? 'primary.light' : 'transparent',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <TableCell>
                                            {project.name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>

                {/* Right Content Area */}
                <Box width="85%">
                    {selectedProject && (
                        <>
                            <Box display="flex" justifyContent="space-between" marginBottom={2}>
                                <Typography variant="h5">{selectedProject.name}</Typography>
                                <Box>
                                    <IconButton color="primary" onClick={() => handleOpenEditProjectDialog(selectedProject)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDeleteProject(selectedProject.id)}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box marginBottom={4}>
                                <Typography variant="h6">Сайты <IconButton color="primary" size="small" onClick={()=>handleOpenAddDomainDialog(selectedProject.id)}><Add /></IconButton></Typography>
                                <Table size={'small'} stickyHeader={true} padding={'none'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Домен</TableCell>
                                            <TableCell align="center">Режим</TableCell>
                                            <TableCell align="center">Тип</TableCell>
                                            <TableCell align="center">РКН</TableCell>
                                            <TableCell align="center">Статус</TableCell>
                                            <TableCell align="center">Сервер</TableCell>
                                            <TableCell align="center">Cloudflare</TableCell>
                                            <TableCell align="center">Действия</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {projectDomains[selectedProject.id]?.map((domain) => (
                                            <TableRow key={domain.id}>
                                                <TableCell>{domain.domain_name}</TableCell>
                                                <TableCell align="center">
                                                    {domain.transfer_type === 'auto' ?
                                                        <Tooltip title="Автоматически">
                                                            <HdrAutoIcon size="small" color="primary"/>
                                                        </Tooltip>
                                                        :
                                                        domain.transfer_type === 'manual' ?
                                                            <Tooltip title="Вручную">
                                                                <BackHandIcon size="small" color="warning"/>
                                                            </Tooltip>
                                                            :
                                                            domain.transfer_type
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title={domain.type}>
                                                        <InfoIcon size="small" color={domain.type === 'main' ? "primary" : "warning"}/>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {domain.status === 'available' ?
                                                        <Tooltip title="Доступен">
                                                            <CheckCircleIcon size="small" color="success"/>
                                                        </Tooltip>
                                                        :
                                                    domain.status === 'blocked' ?
                                                        <Tooltip title="Заблокирован">
                                                            <RemoveCircleIcon size="small" color="error"/>
                                                        </Tooltip>
                                                        :
                                                        domain.status
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                        <Tooltip title={
                                                            <div>
                                                                <Typography variant="caption" component="div" style={{ marginTop: 4 }}>
                                                                    Код ответа: <strong>{domain.http_status_code}</strong>
                                                                </Typography>
                                                                <Typography variant="caption" component="div">
                                                                    Последняя проверка: {new Date(domain.last_http_check_at).toLocaleString()}
                                                                </Typography>
                                                            </div>
                                                        }>
                                                            {(() => {
                                                                const isOlderThan24Hours = domain.last_http_check_at
                                                                    ? differenceInHours(new Date(), parseISO(domain.last_http_check_at)) > 24
                                                                    : false;

                                                                if (domain.http_status_code >= 200 && domain.http_status_code <= 299) {
                                                                    return <CheckCircleIcon size="small" color="success" />;
                                                                } else if (isOlderThan24Hours) {
                                                                    return <InfoIcon size="small" color="warning" />;
                                                                } else if (domain.http_status_code) {
                                                                    return <RemoveCircleIcon size="small" color="error" />;
                                                                } else {
                                                                    return <div>-</div>;
                                                                }
                                                            })()}
                                                        </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">{domain.server_name}</TableCell>
                                                <TableCell align="center">{domain.cloudflare_account_name || " --"}</TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Редактировать">
                                                        <IconButton size="small" color="primary" onClick={() => handleOpenEditDomainDialog(domain)}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Переенести">
                                                        <IconButton size="small" color="primary" onClick={() => handleOpenTransferDialog(domain.id)}>
                                                            <TransferWithinAStation />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="История переездов">
                                                        <IconButton size="small" color="primary" onClick={() => handleOpenHistoryDialog(domain.id)}>
                                                            <History />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Изменить DNS">
                                                        <IconButton size="small" color="primary" onClick={() => handleOpenChangeDnsDialog(domain.domain_name)}>
                                                            <ChangeCircleOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Посмотреть редиректы">
                                                        <IconButton size="small" color="primary" onClick={() => handleOpenRedirectModal(domain.id)}>
                                                            <ShuffleIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Подклейка Доменов">
                                                        <IconButton size="small" color="primary" onClick={() => handlePageRuleDialog(domain)}>
                                                            <PlaylistAddIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Удалить домен">
                                                        <IconButton size="small" color="secondary" onClick={() => handleOpenDeleteDomainDialog(domain)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>

                            <Box>
                                <Typography variant="h6">Резервные домены <IconButton color="primary" size="small" onClick={()=>handleOpenAddDomainDialog(selectedProject.id)}><Add /></IconButton></Typography>
                                <Table size={'small'} stickyHeader={true} padding={'none'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Домен</TableCell>
                                            <TableCell>Cloudflare Профиль</TableCell>
                                            <TableCell>Действия</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {unassignedDomains.map((domain) => (
                                            <TableRow key={domain.id}>
                                                <TableCell>{domain.domain_name}</TableCell>
                                                <TableCell>{domain.cloudflare_account_name}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="Редактировать">
                                                        <IconButton color="primary" onClick={() => handleOpenEditDomainDialog(domain)}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Изменить DNS">
                                                        <IconButton color="primary" onClick={() => handleOpenChangeDnsDialog(domain.domain_name)}>
                                                            <ChangeCircleOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Удалить домен">
                                                        <IconButton color="secondary" onClick={() => handleOpenDeleteDomainDialog(domain)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            <Dialog open={openCreateProjectDialog} onClose={handleCloseCreateProjectDialog}>
                <DialogTitle>Создать новый проект</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Название проекта"
                        fullWidth
                        value={newProjectName}
                        onChange={(e) => setNewProjectName(e.target.value)}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCreateProjectDialog} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleCreateProject} color="primary">
                        Создать
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEditProjectDialog} onClose={handleCloseEditProjectDialog}>
                <DialogTitle>Редактировать проект</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Название проекта"
                        fullWidth
                        value={editProjectName}
                        onChange={(e) => setEditProjectName(e.target.value)}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditProjectDialog} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleEditProject} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openChangeDnsDialog} onClose={handleCloseChangeDnsDialog}>
                <DialogTitle>Изменить DNS</DialogTitle>
                <DialogContent>
                    <ChangeDNS
                        token={token}
                        initialDomain={dnsDomain}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChangeDnsDialog} color="primary">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>

            <DomainTransferDialog
                open={openTransferDialog}
                onClose={handleCloseTransferDialog}
                onTransfer={handleTransfer}
                token={token}
                sourceDomainId={sourceDomainId}
            />
            <DomainTransferHistoryDialog
                open={openHistoryDialog}
                onClose={handleCloseHistoryDialog}
                token={token}
                domainId={historyDomainId}
                onDomainDeleted={handleDomainDeleted}
            />
            <DomainRedirectModal
                open={openRedirectModal}
                onClose={() => {
                    setOpenRedirectModal(false);
                    setRedirectDomain(null);
                }}
                token={token}
                domainId={redirectDomain}
            />
            <PageRuleDialog
                open={openPageRuleDialog}
                onClose={() => {
                    setOpenPageRuleDialog(false);
                    setPageRuleDomain(null);
                }}
                token={token}
                domain={pageRuleDomain}
            />

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDomainDialog}>
                <DialogTitle>Подтвердить удаление</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить домен <strong>{selectedDomain?.domain_name}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDomainDialog} color="primary">
                        Отменить
                    </Button>
                    <Button onClick={() => handleDeleteDomain(selectedDomain?.id)} color="secondary">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openAddDomainDialog} onClose={handleCloseAddDomainDialog}>
                <DialogTitle>Add New Domain</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Добавьте новый домен в проект.
                    </DialogContentText>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Проект</InputLabel>
                        <Select
                            value={newDomain.project_id}
                            onChange={(e) => setNewDomain({ ...newDomain, project_id: e.target.value })}
                        >
                            {projects.map((project) => (
                                <MenuItem key={project.id} value={project.id}>
                                    {project.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Домен"
                        value={newDomain.domain_name}
                        onChange={handleDomainNameChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Путь"
                        value={newDomain.file_path}
                        onChange={(e) => setNewDomain({ ...newDomain, file_path: e.target.value })}
                        margin="normal"
                        fullWidth
                    />
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Тип</InputLabel>
                        <Select
                            value={newDomain.type}
                            onChange={(e) => setNewDomain({ ...newDomain, type: e.target.value })}
                        >
                            <MenuItem value="main">Main</MenuItem>
                            <MenuItem value="transfer">Transfer</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Режим</InputLabel>
                        <Select
                            value={newDomain.transfer_type}
                            onChange={(e) => setNewDomain({ ...newDomain, transfer_type: e.target.value })}
                        >
                            <MenuItem value="auto">Автоматический</MenuItem>
                            <MenuItem value="manual">Ручной</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Сервер</InputLabel>
                        <Select
                            value={newDomain.server_id}
                            onChange={(e) => setNewDomain({ ...newDomain, server_id: e.target.value })}
                        >
                            {servers.map((server) => (
                                <MenuItem key={server.id} value={server.id}>
                                    {server.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDomainDialog} color="primary">
                        Отменить
                    </Button>
                    <Button onClick={handleAddDomainToProject} color="primary">
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEditDomainDialog} onClose={handleCloseEditDomainDialog}>
                <DialogTitle>Edit Domain</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Отредактируйте данные домена.
                    </DialogContentText>
                    <TextField
                        label="Домен"
                        value={editDomain?.domain_name || ''}
                        onChange={(e) => setEditDomain({ ...editDomain, domain_name: e.target.value })}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Путь"
                        value={editDomain?.file_path || ''}
                        onChange={(e) => setEditDomain({ ...editDomain, file_path: e.target.value })}
                        margin="normal"
                        fullWidth
                    />
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Тип</InputLabel>
                        <Select
                            value={editDomain?.type || ''}
                            onChange={(e) => setEditDomain({ ...editDomain, type: e.target.value })}
                        >
                            <MenuItem value="main">Main</MenuItem>
                            <MenuItem value="transfer">Transfer</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Режим</InputLabel>
                        <Select
                            value={editDomain?.transfer_type}
                            onChange={(e) => setEditDomain({ ...editDomain, transfer_type: e.target.value })}
                        >
                            <MenuItem value="auto">Автоматический</MenuItem>
                            <MenuItem value="manual">Ручной</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Сервер</InputLabel>
                        <Select
                            value={editDomain?.server_id || ''}
                            onChange={(e) => setEditDomain({ ...editDomain, server_id: e.target.value })}
                        >
                            {servers.map((server) => (
                                <MenuItem key={server.id} value={server.id}>
                                    {server.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Cloudflare Account</InputLabel>
                        <Select
                            value={editDomain?.cloudflare_account_id || ''}
                            onChange={(e) => setEditDomain({ ...editDomain, cloudflare_account_id: e.target.value })}
                        >
                            {cloudflareAccounts.map((account) => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Проект</InputLabel>
                        <Select
                            value={editDomain?.project_id || ''}
                            onChange={(e) => setEditDomain({ ...editDomain, project_id: e.target.value })}
                        >
                            {projects.map((project) => (
                                <MenuItem key={project.id} value={project.id}>
                                    {project.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDomainDialog} color="primary">
                        Отменить
                    </Button>
                    <Button onClick={handleEditDomain} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ProjectsNew;
