import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Box, IconButton
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import API_BASE_URL from '../apiConfig';

const Cloudflare = ({ token }) => {
  const [accounts, setAccounts] = useState([]);
  const [newAccountName, setNewAccountName] = useState('');
  const [newAccountEmail, setNewAccountEmail] = useState('');
  const [newAccountApiKey, setNewAccountApiKey] = useState('');
  const [error, setError] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null); // Состояние для редактирования

  useEffect(() => {
    fetchAccounts();
  }, [token]);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/cloudflare_accounts`, {
        headers: { Authorization: token }
      });
      setAccounts(response.data.data);
    } catch (error) {
      setError('Failed to fetch accounts');
    }
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddAccount = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/cloudflare_accounts`, {
        name: newAccountName,
        api_key: newAccountApiKey,
        email: newAccountEmail
      }, {
        headers: { Authorization: token }
      });
      fetchAccounts();
      handleCloseAddDialog();
      setNewAccountName('');
      setNewAccountEmail('');
      setNewAccountApiKey('');
    } catch (error) {
      setError('Failed to add account');
    }
  };

  const handleOpenEditDialog = (account) => {
    setEditingAccount(account);
    setNewAccountName(account.name);
    setNewAccountEmail(account.email);
    setNewAccountApiKey(account.api_key);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditingAccount(null);
  };

  const handleEditAccount = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/cloudflare_accounts/${editingAccount.id}`, {
        name: newAccountName,
        api_key: newAccountApiKey,
        email: newAccountEmail
      }, {
        headers: { Authorization: token }
      });
      fetchAccounts();
      handleCloseEditDialog();
    } catch (error) {
      setError('Failed to edit account');
    }
  };

  const handleDeleteAccount = async (accountId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/cloudflare_accounts/${accountId}`, {
        headers: { Authorization: token }
      });
      fetchAccounts();
    } catch (error) {
      setError('Failed to delete account');
    }
  };

  return (
    <Container>
      {error && <Typography color="error">{error}</Typography>}
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
          Add Account
        </Button>
      </Box>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>API Key</TableCell>
              <TableCell>NS Servers</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.email}</TableCell>
                <TableCell>{account.api_key}</TableCell>
                <TableCell>
                  {account.ns_1 ? `${account.ns_1}, ${account.ns_2}` : 'N/A'}
                </TableCell>
                <TableCell>{new Date(account.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenEditDialog(account)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteAccount(account.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* Add Account Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Cloudflare Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details of the new Cloudflare account.
          </DialogContentText>
          <TextField
            label="Account Name"
            value={newAccountName}
            onChange={(e) => setNewAccountName(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Email"
            value={newAccountEmail}
            onChange={(e) => setNewAccountEmail(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="API Key"
            value={newAccountApiKey}
            onChange={(e) => setNewAccountApiKey(e.target.value)}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddAccount} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Account Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Cloudflare Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the details of the Cloudflare account.
          </DialogContentText>
          <TextField
            label="Account Name"
            value={newAccountName}
            onChange={(e) => setNewAccountName(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Email"
            value={newAccountEmail}
            onChange={(e) => setNewAccountEmail(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="API Key"
            value={newAccountApiKey}
            onChange={(e) => setNewAccountApiKey(e.target.value)}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditAccount} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Cloudflare;
