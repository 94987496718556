const { toASCII } = require('punycode');

class Helper {
    static validateAndCleanUrl = (url) => {
        try {
            // Удаляем протокол (http://, https://) и слеш в конце
            const cleanUrl = url.replace(/^https?:\/\//, '').replace(/\/$/, '');

            // Преобразуем в Punycode (если домен кириллический)
            const punycodeUrl = toASCII(cleanUrl);

            // Проверяем, что это валидный домен
            const domainRegex = /^[a-zA-Z0-9.-]+$/;
            if (!domainRegex.test(punycodeUrl)) {
                throw new Error(`Некорректный URL: ${url}`);
            }

            return punycodeUrl; // Возвращаем в формате Punycode
        } catch (error) {
            throw new Error(`Ошибка проверки URL: ${error.message}`);
        }
    };
}

module.exports = Helper;