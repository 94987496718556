import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, MenuItem, Select, FormControl, InputLabel, IconButton, Collapse, Box
} from '@mui/material';
import { ExpandMore, ExpandLess, Edit, Delete, TransferWithinAStation, History } from '@mui/icons-material';
import API_BASE_URL from '../apiConfig';
import DomainTransferDialog from './DomainTransferDialog';
import DomainTransferHistoryDialog from './DomainTransferHistoryDialog';

const Projects = ({ token }) => {
  const [projects, setProjects] = useState([]);
  const [servers, setServers] = useState([]);
  const [cloudflareAccounts, setCloudflareAccounts] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [newProjectName, setNewProjectName] = useState('');
  const [editProjectName, setEditProjectName] = useState('');
  const [newDomain, setNewDomain] = useState({ domain_name: '', file_path: '', type: 'main', server_id: '', project_id: '' });
  const [editDomain, setEditDomain] = useState(null);
  const [error, setError] = useState('');
  const [openAddDomainDialog, setOpenAddDomainDialog] = useState(false);
  const [openEditProjectDialog, setOpenEditProjectDialog] = useState(false);
  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);
  const [openEditDomainDialog, setOpenEditDomainDialog] = useState(false);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [expandedProjectId, setExpandedProjectId] = useState(null);
  const [projectDomains, setProjectDomains] = useState({});
  const [sourceDomainId, setSourceDomainId] = useState(null);
  const [historyDomainId, setHistoryDomainId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [unassignedDomains, setUnassignedDomains] = useState([]);


  const fetchProjects = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user_projects`, {
        headers: { Authorization: token }
      });
      setProjects(response.data.data);
      // Fetch domains for expanded projects
      response.data.data.forEach(project => {
        if (expandedProjectId === project.id) {
          fetchProjectDomains(project.id);
        }
      });
    } catch (error) {
      setError('Failed to fetch projects');
    }
  }, [token, expandedProjectId]);

  const fetchUnassignedDomains = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/unassigned_domains`, {
        headers: { Authorization: token }
      });
      setUnassignedDomains(response.data.data);
    } catch (error) {
      setError('Failed to fetch unassigned domains');
    }
  }, [token]);

  const fetchServers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/servers`, {
        headers: { Authorization: token }
      });
      setServers(response.data.data);
    } catch (error) {
      setError('Failed to fetch servers');
    }
  }, [token]);

  const fetchCloudflareAccounts = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/cloudflare_accounts`, {
        headers: { Authorization: token }
      });
      setCloudflareAccounts(response.data.data);
    } catch (error) {
      setError('Failed to fetch Cloudflare accounts');
    }
  }, [token]);

  const fetchProjectDomains = useCallback(async (projectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/project_domains/${projectId}`, {
        headers: { Authorization: token }
      });
      setProjectDomains((prev) => ({
        ...prev,
        [projectId]: response.data.data,
      }));
    } catch (error) {
      setError('Failed to fetch project domains');
    }
  }, [token]);


  const handleDomainDeleted = () => {
    handleCloseHistoryDialog();
    window.location.reload();
  };

  useEffect(() => {
    fetchProjects();
    fetchUnassignedDomains();
    fetchServers();
    fetchCloudflareAccounts();
  }, [fetchProjects, fetchUnassignedDomains, fetchServers, fetchCloudflareAccounts]);

  const handleOpenAddDomainDialog = () => {
    setOpenAddDomainDialog(true);
  };

  const handleCloseAddDomainDialog = () => {
    setOpenAddDomainDialog(false);
    setNewDomain({ domain_name: '', file_path: '', type: 'main', server_id: '', project_id: '' });
  };

  const handleDomainNameChange = (e) => {
    const domainName = e.target.value;
    const filePath = `/var/www/www-root/data/www/${domainName}`;
    setNewDomain({ ...newDomain, domain_name: domainName, file_path: filePath });
  };

  const handleAddDomainToProject = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/domains`, {
        ...newDomain,
        status: 'available',
      }, {
        headers: { Authorization: token }
      });
      fetchProjects();
      fetchUnassignedDomains();
      handleCloseAddDomainDialog();
      window.location.reload();
    } catch (error) {
      setError('Failed to add domain to project');
    }
  };

  const handleOpenEditProjectDialog = (project) => {
    setSelectedProject(project);
    setEditProjectName(project.name);
    setOpenEditProjectDialog(true);
  };

  const handleCloseEditProjectDialog = () => {
    setOpenEditProjectDialog(false);
    setSelectedProject(null);
  };

  const handleEditProject = async () => {
    try {
      await axios.put(`${API_BASE_URL}/api/projects/${selectedProject.id}`, {
        name: editProjectName
      }, {
        headers: { Authorization: token }
      });
      fetchProjects();
      handleCloseEditProjectDialog();
    } catch (error) {
      setError('Failed to edit project');
    }
  };

  const handleOpenCreateProjectDialog = () => {
    setOpenCreateProjectDialog(true);
  };

  const handleCloseCreateProjectDialog = () => {
    setOpenCreateProjectDialog(false);
    setNewProjectName('');
  };

  const handleCreateProject = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/projects`, {
        name: newProjectName
      }, {
        headers: { Authorization: token }
      });
      fetchProjects();
      handleCloseCreateProjectDialog();
    } catch (error) {
      setError('Failed to create project');
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/projects/${projectId}`, {
        headers: { Authorization: token }
      });
      fetchProjects();
      fetchUnassignedDomains();
    } catch (error) {
      setError('Failed to delete project');
    }
  };

  const handleExpandProject = (projectId) => {
    setExpandedProjectId((prev) => (prev === projectId ? null : projectId));
    if (!projectDomains[projectId]) {
      fetchProjectDomains(projectId);
    }
  };

  const handleOpenEditDomainDialog = async (domain) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/domains/${domain.id}`, {
        headers: { Authorization: token }
      });
      setEditDomain(response.data.data);
      setOpenEditDomainDialog(true);
    } catch (error) {
      setError('Failed to fetch domain details');
    }
  };

  const handleCloseEditDomainDialog = () => {
    setOpenEditDomainDialog(false);
    setEditDomain(null);
    // Reload project data to ensure UI is up to date
    fetchProjects();
    if (editDomain?.project_id) {
      fetchProjectDomains(editDomain.project_id);
    }
  };


  const handleEditDomain = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/domains/${editDomain.id}`, {
        ...editDomain,
      }, {
        headers: { Authorization: token }
      });
      handleCloseEditDomainDialog();
      // Reload project and domains data
      fetchProjects();
      if (editDomain.project_id) {
        fetchProjectDomains(editDomain.project_id);
      }
      // Fetch the previous project domains if project_id was changed
      if (editDomain.project_id !== selectedProject.id) {
        fetchProjectDomains(selectedProject.id);
      }
    } catch (error) {
      // setError('Failed to edit domain');
      // console.log(error)
    }
  };


  const handleDeleteDomain = async (domainId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/domains/${domainId}`, {
        headers: { Authorization: token }
      });
      setDeleteDialogOpen(false);
      fetchProjects();
      window.location.reload();

    } catch (error) {
      setError('Failed to delete domain');
    }
  };

  const handleOpenDeleteDomainDialog = (domain) => {
    setSelectedDomain(domain);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDomainDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedDomain(null);
  };

  const handleOpenTransferDialog = (domainId) => {
    setSourceDomainId(domainId);
    setOpenTransferDialog(true);
  };

  const handleCloseTransferDialog = () => {
    setOpenTransferDialog(false);
    setSourceDomainId(null);
  };

  const handleTransfer = () => {
    fetchProjects();
    if (sourceDomainId) {
      const projectId = projects.find(project =>
        projectDomains[project.id]?.some(domain => domain.id === sourceDomainId)
      )?.id;
      if (projectId) {
        fetchProjectDomains(projectId);
      }
    }
  };

  const handleOpenHistoryDialog = (domainId) => {
    setHistoryDomainId(domainId);
    setOpenHistoryDialog(true);
  };

  const handleCloseHistoryDialog = () => {
    setOpenHistoryDialog(false);
    setHistoryDomainId(null);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Projects
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateProjectDialog}
          sx={{ mr: 2 }}
        >
          Добавить проект
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenAddDomainDialog}
        >
          Добавить домен
        </Button>
      </Box>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Проект</TableCell>
              <TableCell>Домен</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Действия</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <React.Fragment key={project.id}>
                <TableRow>
                  <TableCell>{project.name}</TableCell>
                  <TableCell>{project.domain_count}</TableCell>
                  <TableCell>{new Date(project.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Button variant="contained" color="secondary" onClick={() => handleDeleteProject(project.id)} sx={{ mr: 1 }}>
                        Delete
                      </Button>
                      <IconButton color="primary" onClick={() => handleOpenEditProjectDialog(project)}>
                        <Edit />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleExpandProject(project.id)}>
                      {expandedProjectId === project.id ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Collapse in={expandedProjectId === project.id} timeout="auto" unmountOnExit>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Domain Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Cloudflare Account</TableCell>
                            <TableCell>Server</TableCell>
                            <TableCell>Date Added</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projectDomains[project.id]?.map((domain) => (
                            <TableRow key={domain.id}>
                              <TableCell>{domain.domain_name}</TableCell>
                              <TableCell>{domain.type}</TableCell>
                              <TableCell>{domain.status}</TableCell>
                              <TableCell style={{ color: domain.cloudflare_account_name ? 'inherit' : 'red' }}>
                                {domain.cloudflare_account_name || 'Unassigned'}
                              </TableCell>
                              <TableCell>{domain.server_name || 'Unassigned'}</TableCell>
                              <TableCell>{new Date(domain.created_at).toLocaleDateString()}</TableCell>
                              <TableCell>
                                <IconButton color="primary" onClick={() => handleOpenEditDomainDialog(domain)}>
                                  <Edit />
                                </IconButton>
                                <IconButton color="primary" onClick={() => handleOpenTransferDialog(domain.id)}>
                                  <TransferWithinAStation />
                                </IconButton>
                                <IconButton color="primary" onClick={() => handleOpenHistoryDialog(domain.id)}>
                                  <History />
                                </IconButton>
                                <IconButton color="secondary" onClick={() => handleOpenDeleteDomainDialog(domain)}>
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Box marginTop={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Не назначенные домены
        </Typography>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Домен</TableCell>
                <TableCell>Дата добавления</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unassignedDomains.map((domain) => (
                <TableRow key={domain.id}>
                  <TableCell>{domain.domain_name}</TableCell>
                  <TableCell>{new Date(domain.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenEditDomainDialog(domain)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleOpenDeleteDomainDialog(domain)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>


      <Dialog open={openAddDomainDialog} onClose={handleCloseAddDomainDialog}>
        <DialogTitle>Add New Domain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Добавьте новый домен в проект.
          </DialogContentText>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Проект</InputLabel>
            <Select
              value={newDomain.project_id}
              onChange={(e) => setNewDomain({ ...newDomain, project_id: e.target.value })}
            >
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Домен"
            value={newDomain.domain_name}
            onChange={handleDomainNameChange}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Путь"
            value={newDomain.file_path}
            onChange={(e) => setNewDomain({ ...newDomain, file_path: e.target.value })}
            margin="normal"
            fullWidth
          />
          <FormControl margin="normal" fullWidth>
            <InputLabel>Тип</InputLabel>
            <Select
              value={newDomain.type}
              onChange={(e) => setNewDomain({ ...newDomain, type: e.target.value })}
            >
              <MenuItem value="main">Main</MenuItem>
              <MenuItem value="transfer">Transfer</MenuItem>
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Сервер</InputLabel>
            <Select
              value={newDomain.server_id}
              onChange={(e) => setNewDomain({ ...newDomain, server_id: e.target.value })}
            >
              {servers.map((server) => (
                <MenuItem key={server.id} value={server.id}>
                  {server.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDomainDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={handleAddDomainToProject} color="primary">
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDomainDialog} onClose={handleCloseEditDomainDialog}>
        <DialogTitle>Edit Domain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Отредактируйте данные домена.
          </DialogContentText>
          <TextField
            label="Домен"
            value={editDomain?.domain_name || ''}
            onChange={(e) => setEditDomain({ ...editDomain, domain_name: e.target.value })}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Путь"
            value={editDomain?.file_path || ''}
            onChange={(e) => setEditDomain({ ...editDomain, file_path: e.target.value })}
            margin="normal"
            fullWidth
          />
          <FormControl margin="normal" fullWidth>
            <InputLabel>Тип</InputLabel>
            <Select
              value={editDomain?.type || ''}
              onChange={(e) => setEditDomain({ ...editDomain, type: e.target.value })}
            >
              <MenuItem value="main">Main</MenuItem>
              <MenuItem value="transfer">Transfer</MenuItem>
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Сервер</InputLabel>
            <Select
              value={editDomain?.server_id || ''}
              onChange={(e) => setEditDomain({ ...editDomain, server_id: e.target.value })}
            >
              {servers.map((server) => (
                <MenuItem key={server.id} value={server.id}>
                  {server.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Cloudflare Account</InputLabel>
            <Select
              value={editDomain?.cloudflare_account_id || ''}
              onChange={(e) => setEditDomain({ ...editDomain, cloudflare_account_id: e.target.value })}
            >
              {cloudflareAccounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Проект</InputLabel>
            <Select
              value={editDomain?.project_id || ''}
              onChange={(e) => setEditDomain({ ...editDomain, project_id: e.target.value })}
            >
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDomainDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={handleEditDomain} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditProjectDialog} onClose={handleCloseEditProjectDialog}>
        <DialogTitle>Edit Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Отредактируйте имя проекта.
          </DialogContentText>
          <TextField
            label="Проект"
            value={editProjectName}
            onChange={(e) => setEditProjectName(e.target.value)}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditProjectDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={handleEditProject} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCreateProjectDialog} onClose={handleCloseCreateProjectDialog}>
        <DialogTitle>Создать новый проект</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите имя нового проекта.
          </DialogContentText>
          <TextField
            label="Новое название проекта"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateProjectDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={handleCreateProject} color="primary">
            Создать
          </Button>
        </DialogActions>
      </Dialog>
      <DomainTransferDialog
        open={openTransferDialog}
        onClose={handleCloseTransferDialog}
        onTransfer={handleTransfer}
        token={token}
        sourceDomainId={sourceDomainId}
      />
      <DomainTransferHistoryDialog
        open={openHistoryDialog}
        onClose={handleCloseHistoryDialog}
        token={token}
        domainId={historyDomainId}
        onDomainDeleted={handleDomainDeleted}
      />
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDomainDialog}>
        <DialogTitle>Подтвердить удаление</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите удалить домен <strong>{selectedDomain?.domain_name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDomainDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={() => handleDeleteDomain(selectedDomain?.id)} color="secondary">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Projects;
