import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Typography, Button, Box, FormControl, InputLabel, Select, MenuItem,
  CircularProgress, Snackbar, Alert
} from '@mui/material';
import API_BASE_URL from '../apiConfig';

const ChangeDNS = ({ token, initialDomain }) => {
  const [cloudflareAccounts, setCloudflareAccounts] = useState([]);
  const [servers, setServers] = useState([]);
  const [domains, setDomains] = useState([]);
  const [filteredServers, setFilteredServers] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedServer, setSelectedServer] = useState('');
  const [tls, setTls] = useState('off');
  const [selectedDomain, setSelectedDomain] = useState(initialDomain || '');
  const [ipAddress, setIpAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    fetchCloudflareAccounts();
    fetchServers();
    fetchDomains();
  }, [token]);

  useEffect(() => {
    if (initialDomain) {
      handleDomainChange(initialDomain, true);
    }
  }, [domains, initialDomain]);

  const fetchCloudflareAccounts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/cloudflare_accounts`, {
        headers: { Authorization: token }
      });
      setCloudflareAccounts(response.data.data);
    } catch (error) {
      setError('Failed to fetch Cloudflare accounts');
    }
  };

  const fetchServers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/servers`, {
        headers: { Authorization: token }
      });
      setServers(response.data.data);
    } catch (error) {
      setError('Failed to fetch servers');
    }
  };

  const fetchDomains = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/domains`, {
        headers: { Authorization: token }
      });
      setDomains(response.data.data);
    } catch (error) {
      setError('Failed to fetch domains');
    }
  };

  const handleDomainChange = (e, preSelect = false) => {
    const selectedDomainName = preSelect ? e : e.target.value;
    setSelectedDomain(selectedDomainName);

    // Фильтруем серверы в зависимости от выбранного домена
    const domainData = domains.find((domain) => domain.domain_name === selectedDomainName);
    if (domainData) {
      let filterServ = servers.filter(server => server.id === domainData.server_id);
      setFilteredServers(filterServ);
      setSelectedServer(domainData.server_id);
      setIpAddress(filterServ[0]?.ssh_host);
    }
  };

  const handleChangeDNS = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await axios.post(`${API_BASE_URL}/api/manage_dns`, {
        accountId: selectedAccount,
        domainName: selectedDomain,
        ipAddress: ipAddress,
        tls: tls
      }, {
        headers: { Authorization: token }
      });
      setLoading(false);
      setMessage(response.data.message);
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      setError('Failed to change DNS');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
      <Container>
        {error && <Typography color="error">{error}</Typography>}
        {message && <Typography color="primary">{message}</Typography>}
        <Box display="flex" flexDirection="column" maxWidth="400px" margin="0 auto">
          <FormControl margin="normal" fullWidth>
            <InputLabel>Cloudflare Account</InputLabel>
            <Select
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
            >
              {cloudflareAccounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Domain</InputLabel>
            <Select
                value={selectedDomain}
                onChange={(e) => handleDomainChange(e, false)}
                disabled={!!initialDomain} // Блокируем выбор, если начальный домен уже установлен
            >
              {domains.map((domain) => (
                  <MenuItem key={domain.id} value={domain.domain_name}>
                    {domain.domain_name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Server</InputLabel>
            <Select
                value={selectedServer}
                onChange={(e) => setSelectedServer(e.target.value)}
                disabled={!selectedDomain || !!initialDomain} // Отключаем, если домен не выбран или начальный домен установлен
            >
              {filteredServers.map((server) => (
                  <MenuItem key={server.id} value={server.id}>
                    {server.name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>TLS 1.3</InputLabel>
            <Select
                value={tls}
                onChange={(e) => setTls(e.target.value )}
            >
              <MenuItem value="off">Выключить</MenuItem>
              <MenuItem value="on">Включить</MenuItem>
            </Select>
          </FormControl>
          <Button
              variant="contained"
              color="primary"
              onClick={handleChangeDNS}
              margin="normal"
              disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Change DNS'}
          </Button>
        </Box>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={error ? "error" : "success"}>
            {error || message}
          </Alert>
        </Snackbar>
      </Container>
  );
};

export default ChangeDNS;
