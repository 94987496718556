import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const DropdownButton = ({ title, menuItems, buttonStyle }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Проверка, активен ли один из путей меню
    const isMenuItemSelected = menuItems.some(item => location.pathname === item.path);

    return (
        <>
            <Button
                onClick={handleMenuOpen}
                sx={{
                    ...buttonStyle,
                    ...(isMenuItemSelected && {
                        backgroundColor: '#2F3235',
                        color: '#fff',
                        borderRadius: '4px'
                    })
                }}
            >
                {title}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'dropdown-button',
                }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        component={Link}
                        to={item.path}
                        onClick={handleMenuClose}
                        sx={buttonStyle(item.path)}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default DropdownButton;
