import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Button, FormControl, InputLabel, Select, MenuItem, Snackbar
} from '@mui/material';
import API_BASE_URL from '../apiConfig';

const DomainTransferDialog = ({ open, onClose, onTransfer, token, sourceDomainId }) => {
  const [transferDomains, setTransferDomains] = useState([]);
  const [targetDomainId, setTargetDomainId] = useState('');
  const [newStatus, setNewStatus] = useState('blocked');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchTransferDomains = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/domains`, {
          headers: { Authorization: token }
        });
        setTransferDomains(response.data.data.filter(domain => domain.type === 'transfer'));
      } catch (error) {
        console.error('Failed to fetch transfer domains', error);
      }
    };

    if (open) {
      fetchTransferDomains();
    }
  }, [token, open]);

  const handleTransfer = async () => {
    if (targetDomainId && newStatus) {
      try {
        await axios.post(`${API_BASE_URL}/api/transfer_explicit`, {
          sourceDomainId,
          targetDomainId,
          newStatus,
        }, {
          headers: { Authorization: token }
        });
        setSnackbarOpen(true);
        onTransfer();
        onClose();
      } catch (error) {
        console.error('Failed to transfer domain', error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Transfer Domain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a target domain to transfer the source domain to.
          </DialogContentText>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Target Domain</InputLabel>
            <Select
              value={targetDomainId}
              onChange={(e) => setTargetDomainId(e.target.value)}
            >
              {transferDomains.map((domain) => (
                <MenuItem key={domain.id} value={domain.id}>
                  {domain.domain_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
            >
              <MenuItem value="available">Available</MenuItem>
              <MenuItem value="blocked">Blocked</MenuItem>
            </Select>
          </FormControl>
          <DialogContentText>
            Status is for the previous domain.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTransfer} color="primary">
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message="Transfer process started"
        autoHideDuration={6000}
      />
    </>
  );
};

export default DomainTransferDialog;
