import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography, CircularProgress, Box
} from '@mui/material';
import API_BASE_URL from '../apiConfig';

const DomainRedirectModal = ({ open, onClose, token, domainId }) => {
    const [redirectData, setRedirectData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setRedirectData([]);
        setLoading(true);
        const fetchRedirectData = async () => {
            try {
                await axios.get(`${API_BASE_URL}/api/check_domain_redirect/${domainId}`, {
                    headers: { Authorization: token }
                }).then((response) => {
                    setLoading(false);
                    setRedirectData(response.data.redirect);
                })
            } catch (error) {
                console.error('Failed to fetch domain transfer history', error);
            }
        };

        if (open) {
            fetchRedirectData();
        }
    }, [token, domainId, open]);

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>Редиректы домена</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Start Domain</TableCell>
                                <TableCell>End Domain</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Box display="flex" flexDirection="column" alignItems="center">
                                            <CircularProgress />
                                            <Typography variant="body2" sx={{ mt: 2 }}>
                                                Загрузка данных...
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : redirectData.length > 0 ? (
                                redirectData.map((record) => (
                                    <TableRow key={record.id}>
                                        <TableCell style={{ fontWeight: 'bold' }}>{record.url}</TableCell>
                                        <TableCell>{record.redirectUrl}</TableCell>
                                        <TableCell>{record.status}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Typography variant="body2">Данные отсутствуют</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DomainRedirectModal;
