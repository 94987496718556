import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';
import API_BASE_URL from '../apiConfig';
import Helper from '../helper';

const PageRuleDialog = ({ open, onClose, token, domain }) => {
    const [cloudflareAccounts, setCloudflareAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [domainsToAttach, setDomainsToAttach] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const { data } = await axios.get(`${API_BASE_URL}/api/cloudflare_accounts`, {
                    headers: { Authorization: token },
                });
                setCloudflareAccounts(data.data || []);
            } catch (error) {
                showSnackbar('Не удалось загрузить аккаунты Cloudflare.', 'error');
            }
        };

        if (open) fetchAccounts();
        else resetState();
    }, [open]);

    const resetState = () => {
        setCloudflareAccounts([]);
        setSelectedAccount('');
        setDomainsToAttach('');
    };

    const showSnackbar = (message, severity = 'info') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleAttach = async () => {
        if (!selectedAccount || !domainsToAttach.trim() || !domain?.server_ip.trim()) {
            showSnackbar('Пожалуйста, заполните все поля.', 'warning');
            return;
        }

        try {
            const domainsArray = domainsToAttach
                .split(/[\n,;]+/)
                .reduce((acc, domain) => {
                    const trimmed = domain.trim();
                    if (trimmed) {
                        acc.push(Helper.validateAndCleanUrl(trimmed));
                    }
                    return acc;
                }, []);

            if (!domainsArray.length) {
                showSnackbar('Введите хотя бы один корректный домен.', 'warning');
                return;
            }

            const payload = {
                accountId: selectedAccount,
                domainName: domain?.domain_name,
                ipAddress: domain?.server_ip.trim(),
                pageRules: domainsArray,
            };

            const response = await axios.post(`${API_BASE_URL}/api/manage_dns`, payload, {
                headers: { Authorization: token },
            });

            console.log('Успешно отправлено:', response.data);
            showSnackbar('Запрос успешно отправлен!', 'success');
            onClose();
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);
            showSnackbar(error.message || 'Произошла ошибка при отправке данных.', 'error');
        }
    };


    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ pb: 0, fontSize: '1.25rem', fontWeight: 'bold' }}>
                    Подклейка домена
                </DialogTitle>
                <DialogContent sx={{ py: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2, mt:2 }}>
                        <TextField
                            label="Домен к которому подклеивается"
                            value={domain?.domain_name || ''}
                            size="small"
                            fullWidth
                            disabled
                            sx={{ bgcolor: '#f5f5f5', borderRadius: 1, flex: 1 }}
                        />
                        <TextField
                            label={domain?.server_name || ''}
                            value={domain?.server_ip || ''}
                            size="small"
                            fullWidth
                            disabled
                            sx={{ bgcolor: '#f5f5f5', borderRadius: 1, flex: 1 }}
                        />
                    </Box>
                    <FormControl fullWidth size="small">
                        <InputLabel>Cloudflare Account</InputLabel>
                        <Select
                            value={selectedAccount}
                            onChange={(e) => setSelectedAccount(e.target.value)}
                            sx={{ bgcolor: '#fff', borderRadius: 1 }}
                        >
                            {cloudflareAccounts.map((account) => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Домены которые подклеиваются"
                        multiline
                        rows={4}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={domainsToAttach}
                        onChange={(e) => setDomainsToAttach(e.target.value)}
                        sx={{ borderRadius: 1 }}
                    />
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={onClose} color="secondary" variant="outlined" sx={{ mr: 1 }}>
                        Отмена
                    </Button>
                    <Button onClick={handleAttach} color="primary" variant="contained">
                        Подклеить
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PageRuleDialog;
